import React from "react";
import { useHistory } from "react-router-dom";

import logout from "../../Assets/logout.png";
import Headerlogo from "../../Assets/Polaris Logo.svg";
import close from "../../Assets/close.png";
import "./header.css";
import { useState } from "react";

export default function Header() {
  const history = useHistory();
  const [logoutval, setlogoutval] = useState(false);

  const Logout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  const handleOpen = () => {
    setlogoutval(true);
  };

  const handleClose = () => {
    setlogoutval(false);
  };
  console.log("login", localStorage.getItem("login"));
  return (
    <>
      <div>
        {logoutval === true ? (
          <div
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modalBox"
          >
            <div className="modal-content">
              <img src={close} className="closeIcn" onClick={handleClose} />
              <div className="btnAdjHeader">
                Are you sure you want to logout?
              </div>
              <div className="btnAdjHeader">
                <button className="subBtn10" onClick={Logout}>
                  Yes
                </button>

                <button className="subBtn10" onClick={handleClose}>
                  No
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {window.location.pathname === "/form" ? (
          <>
            <div className="header">
              <div>
                <img src={Headerlogo} className="headerlogo" />
              </div>
            </div>
            <div className="headerTitle">
              {localStorage.getItem("organizationName")}
            </div>
            <div
              style={{
                textAlign: "right",
                paddingRight: "4em",
              }}
              className="lgoutdiv"
            >
              <img src={logout} onClick={handleOpen} className="logoutImg" />
            </div>
          </>
        ) : window.location.pathname === "/pdfview" ? (
          <>
            <div>
              <div className="header">
                <div>
                  <img src={Headerlogo} className="headerlogo" />
                </div>
              </div>
              <div className="pdfheaderTitle">
                {localStorage.getItem("organizationName")}
              </div>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "4em",
                }}
                className="lgoutdiv"
              >
                <img
                  src={logout}
                  onClick={handleOpen}
                  className="pdflogoutImg"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="header">
                <div>
                  <img src={Headerlogo} className="headerlogo" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
