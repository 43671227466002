import React, { useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer.js";
import CircularProgress from "@material-ui/core/CircularProgress";
// import api from "../Service/API/index.js";
import './success.css'
import close from "../Assets/close.png";
import Modal from "@material-ui/core/Modal";
import bannerImage from "../Assets/bannerImage.png";
import success from "../Assets/success.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = "100vh";

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function Form(props) {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [calendlyStartTime, setcalendlyStartTime] = React.useState("");
  const [calendlyconvertTime, setcalendlyconvertTime] = React.useState("");
  const [copied, setcopied] = React.useState(false);

  let history = useHistory();

  useEffect(() => {
    setOpen(true)
  }, []);


  const handleClose = () => {
    history.push("/");
  };

  //modal box body
  const body = (
    <div style={modalStyle} className="paperModal">
      <div className="closeHolder" onClick={handleClose}>
        <img src={close} />
      </div>
      <div className="content">
        <div className="rander">
          <img src={bannerImage} />
        </div>
        <div className="center">
          <img src={success} />
        </div>
      </div>

      <div className="successmsg">
        <div className="appcoinfirm">Verification Portal</div>
        <div className="confirmationCode">
          <div>
            <h2>Name:</h2>
          </div>
          <div>
            <h2>
              John
            </h2>
          </div>
        </div>
        <div className="confirmationCode">
          <div>
            <h2>Status:</h2>
          </div>
          <div style={{ display: "flex" }}>
            <h2>Verified</h2>
          </div>
        </div>

        <div className="confirmationCode">
          <div>
            <h2>Description:</h2>
          </div>
          <div>
            <h2 className="dateH2">
              Traveler has received all vaccine doses {">"} 14 days by travel date
            </h2>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div className="">
        {isLoaded == true ? (
          <div className="loaderAdj">
            <CircularProgress />
            <div style={{ height: "70vh" }}></div>
          </div>
        ) : (
          <div style={{ height: "100vh" }}></div>
        )}
      </div>
    </>
  );
}
