import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import "./Login.css";
import "date-fns";
import Loginbg from "../../Assets/bg_image.png";
import * as actions from "../../Action/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import PhoneInput from "react-phone-input-2";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
export default function Login() {
  const history = useHistory();
  const [emailId, setemailId] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [ShowemailError, setShowemailError] = React.useState(false);
  const [Showvalidemail, setShowvalidemail] = React.useState(false);
  const [Showpassword, setShowpassword] = React.useState(false);
  const [ShowSuccess, setShowSuccess] = React.useState(false);
  const [ShowError, setShowerror] = React.useState(false);
  // localStorage.clear();

  useEffect(() => {
    console.log("login", localStorage.getItem("login"));
    if (localStorage.getItem("login") === "true") {
      window.location.replace("/form");
    }
  });

  const [loader, setloader] = React.useState(false);
  // Mobile Number Handle Change
  const handleemailId = (e) => {
    setemailId(e.target.value);
  };
  const handlepassword = (e) => {
    setpassword(e.target.value);
  };
  const formSubmit = () => {
    if (emailId === "") {
      setShowemailError(true);
      return 0;
    } else if (
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(emailId) ===
        false &&
      emailId !== ""
    ) {
      setShowvalidemail(true);
      setShowemailError(false);
      return 0;
    }
    //  else if (password === "") {
    //   setShowpassword(true);
    //   setShowvalidemail(false);
    //   setShowemailError(false);
    //   return 0;
    // }
    else {
      APIcall();
    }
  };
  const APIcall = () => {
    // setShowpassword(false);
    setShowvalidemail(false);
    setShowemailError(false);
    // setloader(true);
    let data = {
      email: emailId,
      // password: password,
    };
    actions.LoginAction(data, (res) => {
      setloader(false);

      console.log("res", res);
      if (res.statusCode === 200) {
        setShowSuccess(true);
        setShowerror(false);
        let resData = {
          firstTimeLogin: res.info.firstTimeLogin,
          preSignedUrl: res.info.preSignedUrl,
          email: emailId,
        };
        localStorage.setItem("adminUserId", res.info.adminUserId);
        console.log(res);
        history.push({
          pathname: "/loginotp",
          state: resData,
        });
      } else {
        console.log("failed");
        setShowerror(true);
      }
    });
  };

  const SignInC = (evt) => {
    if (evt.keyCode === 13) {
      formSubmit();
    }
  };

  return (
    <>
      <Header />
      <ToastContainer />
      {loader === true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div className="containerHolder1">
          <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs={12} md={6} sm={12}>
              <img src={Loginbg} className="loginBg" />
            </Grid>

            <Grid item xs={12} md={6} sm={12}>
              <h1>Login</h1>

              <div className="accordionbg1">
                <div className="accSummary1">
                  <Grid container spacing={3} className="loginGrid">
                    <Grid item xs={12} md={12} sm={12}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Email Id"
                        value={emailId}
                        onChange={handleemailId}
                        onKeyUp={SignInC.bind(this)}
                        inputProps={{
                          autoComplete: "off",
                        }}
                      />
                      {ShowemailError ? (
                        <div className="errorMess">
                          Please enter your Email address
                        </div>
                      ) : Showvalidemail ? (
                        <div className="errorMess">
                          Please enter a valid Email address
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>

                    {/* <Grid item xs={12} md={12} sm={12}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Password"
                        value={password}
                        type="password"
                        onChange={handlepassword}
                        onKeyUp={SignInC.bind(this)}
                      />
                      {Showpassword ? (
                        <div className="errorMess">Please enter Password</div>
                      ) : (
                        <div></div>
                      )}
                    </Grid> */}
                    <Grid item xs={12} md={12} sm={12}>
                      <div className="submitlgin">
                        <div>
                          <button
                            className="btnlgin loginSubmit1"
                            onClick={formSubmit}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                      {ShowError ? (
                        <div className="errorMess1">
                          Email address has not been registered
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
