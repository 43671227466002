import * as ActionConstants from "../Constant/index.js";
import * as api from "../Service/API/index";
// Auth------------
// Login
export function LoginAction(data, res) {
  api.LoginAPI(data, (response) => {
    return res(response);
  });
}
export function SignupAction(data, res) {
  api.SignUpAPI(data, (response) => {
    return res(response);
  });
}
// SendOTP
export function SendOTPAction(data, res) {
  api.SendOTPAPI(data, (response) => {
    return res(response);
  });
}
// Resend OTP
export function ReSendOTPAction(data, res) {
  api.ResendOTPAPI(data, (response) => {
    return res(response);
  });
}
// Validate OTP
export function ValidateOTPAction(data, res) {
  api.ValidateOTPAPI(data, (response) => {
    return res(response);
  });
}
//  Refresh Token
export function RefreshTokenAction(data, res) {
  api.RefreshTokenAPI(data, (response) => {
    return res(response);
  });
}
// Login Authenticate
export function LoginAuthenticateAction(data, res) {
  api.Login_AuthenticateAPI(data, (response) => {
    return res(response);
  });
}
// Signup Authenticate

export function SignupAuthenticateAction(data, res) {
  api.Signup_AuthenticateAPI(data, (response) => {
    return res(response);
  });
}
export function SignupDOBAction(data, res) {
  api.SignupDOBAPI(data, (response) => {
    return res(response);
  });
}
// Forgot password
export function ForgotpasswordAction(data, res) {
  api.ForgotpasswordAPI(data, (response) => {
    return res(response);
  });
}
// Reset password
export function ResetpasswordAction(data, res) {
  api.ResetpasswordAPI(data, (response) => {
    return res(response);
  });
}
// ---------------------------------------------------------------------------------------------------------
// Update profile
export function ProfileAction(data, res) {
  api.Update_ProfileAPI(data, (response) => {
    return res(response);
  });
}
// Get Profile list
export function GetprofileAction(data, res) {
  api.getProfileListAPI(data, (response) => {
    return res(response);
  });
}
// ---------------------------------------------------------------------------------------------------------
// Search FAQ
export function SearchFAQAction(data, res) {
  api.Search_FAQAPI(data, (response) => {
    return res(response);
  });
}
// Get Profile list
export function GetFAQAction(res) {
  api.getFAQListAPI((response) => {
    return res(response);
  });
}
// ---------------------------------------------------------------------------------------------------------
// Drop Down list
export function DropDownAction(res) {
  api.DropDownAPI((response) => {
    return res(response);
  });
}
// ---------------------------------------------------------------------------------------------------------
// History---------------
// History list
export function getHistoryList(data, res) {
  api.getHistoryListAPI(data, (response) => {
    return res(response);
  });
}
// ---------------------------------------------------------------------------------------------------------
// Clinics------------
// Test Clinics list
export function getTestClinicsList(data, res) {
  api.TestClinicsListAPI(data, (response) => {
    return res(response);
  });
}
// Vaccination clinics list
export function getVaccinationClinicsList(data, res) {
  api.VaccinationClinicsListAPI(data, (response) => {
    return res(response);
  });
}
// ---------------------------------------------------------------------------------------------------------
// Appointment------------------------
// Book Vaccination
export function Book_Vaccination(data, res) {
  api.Book_VaccinationAPI(data, (response) => {
    return res(response);
  });
}
// Book_Testing
export function Book_Testing(data, res) {
  api.Book_TestingAPI(data, (response) => {
    return res(response);
  });
}
// ---------------------------------------------------------------------------------------------------------
// export function Book_Vaccination_Request(action, payload) {
//    return { type: action, payload }
// }

// export function Book_Vaccination(res) {
//    console.log("res", res)
//    return function (dispatch) {
//       dispatch(Book_Vaccination_Request(ActionConstants.Book_Vaccination_Constant, res))
//    }
// }
// // ---------------------------------------------------------------------------------------------------------
// export function Book_Testing_Request(action, payload) {
//    return { type: action, payload }
// }

// export function Book_Testing(res) {
//    console.log("res", res)
//    return function (dispatch) {
//       dispatch(Book_Testing_Request(ActionConstants.Book_Testing_Constant, res))
//    }
// }
