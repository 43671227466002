// Auth
export const LOGIN_URL =
  process.env.REACT_APP_API_URL + "verification/portal/login";
export const SIGNUP_URL = process.env.REACT_APP_API_URL + "patient/exp/login";
export const SENDOTP_URL =
  process.env.REACT_APP_API_URL + "patient/exp/otp/send";
export const VALIDATEOTP_URL =
  process.env.REACT_APP_API_URL + "verification/portal/otp/validate";
export const PdfInfo_URL =
  process.env.REACT_APP_API_URL + "verification/portal/agreement/save";
export const PassID_Decrypt_URL =
  process.env.REACT_APP_API_URL + "api/verification/portal/decrypt/code";
export const RESENDOTP_URL =
  process.env.REACT_APP_API_URL + "patient/exp/otp/resend";
export const REFRESHTOKEN_URL =
  process.env.REACT_APP_API_URL + "auth/refresh/token";
export const LOGIN_AUTHENTICATED_URL =
  process.env.REACT_APP_API_URL + "patient/exp/authenticate";
export const SIGNUP_AUTHENTICATED_URL =
  process.env.REACT_APP_API_URL + "patient/exp/authenticate";
export const SIGNUP_DOB_URL =
  process.env.REACT_APP_API_URL + "patient/exp/authenticate";
export const FORGOT_PASSWORD_URL =
  process.env.REACT_APP_API_URL + "patient/exp/authenticate";
export const RESET_PASSWORD_URL =
  process.env.REACT_APP_API_URL + "patient/exp/authenticate";

// Profile
export const PROFILE_URL =
  process.env.REACT_APP_API_URL + "patient/exp/update/profile";
export const GETPROFILE_URL =
  process.env.REACT_APP_API_URL + "patient/exp/get/profile/information";

// State and county
export const DROPDOWN_URL =
  process.env.REACT_APP_API_URL + "patient/exp/dropdown/list";
//FAQ
export const FAQ_URL = process.env.REACT_APP_API_URL + "patient/exp/faq/get";
export const FAQ_SEARCH_URL =
  process.env.REACT_APP_API_URL + "patient/exp/faq/search";
// Clinics
export const TESTCLINICS_LIST_URL =
  process.env.REACT_APP_API_URL + "patient/exp/get/testing/labs";
export const VACCINATIONCLINICS_LIST_URL =
  process.env.REACT_APP_API_URL + "patient/exp/get/vaccination/labs";
// History
export const GETHISTORYLIST_URL =
  process.env.REACT_APP_API_URL + "patient/exp/get/appointment/history";
// Appointment
export const BOOK_VACCINATION_APPOINTMENT_URL =
  process.env.REACT_APP_API_URL + "patient/exp/book/vaccination/appointment";
export const BOOK_TESTING_APPOINTMENT_URL =
  process.env.REACT_APP_API_URL + "patient/exp/book/testing/appointment";
