import axios from "axios";
import * as API_URL from "../API-URL/index.js";

// Auth login

export function LoginAPI(data, res) {
  axios
    .post(API_URL.LOGIN_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
export function SignUpAPI(data, res) {
  axios
    .post(API_URL.SIGNUP_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// Auth Send OTP

export function SendOTPAPI(data, res) {
  axios
    .post(API_URL.SENDOTP_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// Auth Resend OTP

export function ResendOTPAPI(data, res) {
  axios
    .post(
      API_URL.RESENDOTP_URL +
        "?messageId=" +
        data.messageId +
        "&email=" +
        data.email +
        "&messageCode=" +
        data.messageCode
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//
export function Login_AuthenticateAPI(data, res) {
  axios
    .post(API_URL.LOGIN_AUTHENTICATED_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
//
export function SignupDOBAPI(data, res) {
  axios
    .post(API_URL.SIGNUP_DOB_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
export function ForgotpasswordAPI(data, res) {
  axios
    .post(API_URL.FORGOT_PASSWORD_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
//
export function ResetpasswordAPI(data, res) {
  axios
    .post(API_URL.RESET_PASSWORD_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
//
export function Signup_AuthenticateAPI(data, res) {
  axios
    .post(API_URL.SIGNUP_AUTHENTICATED_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// Auth Validate OTP
export function ValidateOTPAPI(data, res) {
  axios
    .get(API_URL.VALIDATEOTP_URL + "?otp=" + data.otp)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//pdfview
export function Update_PdfInfoAPI(data, res) {
  axios
    .post(API_URL.PdfInfo_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
    });
}
//PassID_Decrypt_URL
export function PassID_DecryptAPI(data, res) {
  axios
    .post(API_URL.PassID_Decrypt_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
    });
}

// Auth Refresh Token
export function RefreshTokenAPI(data, res) {
  axios
    .get(API_URL.REFRESHTOKEN_URL + "?refreshToken=" + data.refreshToken, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// ---------------------------------------------------------------------------------------------------------

// Update Profile URL
export function Update_ProfileAPI(data, res) {
  axios
    .post(API_URL.PROFILE_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// Get Profile URL
export function getProfileListAPI(data, res) {
  axios
    .get(API_URL.GETPROFILE_URL + "?patientId=" + data.patientId, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// ---------------------------------------------------------------------------------------------------------

// Search Question URL
export function Search_FAQAPI(data, res) {
  axios
    .post(API_URL.FAQ_SEARCH_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// Get Question URL
export function getFAQListAPI(res) {
  axios
    .get(API_URL.FAQ_URL, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// ---------------------------------------------------------------------------------------------------------
// Drop down
export function DropDownAPI(res) {
  axios
    .get(API_URL.DROPDOWN_URL, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// ---------------------------------------------------------------------------------------------------------
// Get History
export function getHistoryListAPI(data, res) {
  axios
    .get(API_URL.GETHISTORYLIST_URL + "?patientId=" + data.patientId, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// ---------------------------------------------------------------------------------------------------------
// Clinics
export function TestClinicsListAPI(data, res) {
  axios
    .get(API_URL.TESTCLINICS_LIST_URL + "?zipCode=" + data.zipCode, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
export function VaccinationClinicsListAPI(data, res) {
  axios
    .get(API_URL.VACCINATIONCLINICS_LIST_URL + "?zipCode=" + data.zipCode, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// ---------------------------------------------------------------------------------------------------------
// Appointment
export function Book_VaccinationAPI(data, res) {
  axios
    .post(API_URL.BOOK_VACCINATION_APPOINTMENT_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
export function Book_TestingAPI(data, res) {
  axios
    .post(API_URL.BOOK_TESTING_APPOINTMENT_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
// ---------------------------------------------------------------------------------------------------------
