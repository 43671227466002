import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import "./App.css";
import Login from "./Components/Auth/Login";
import Loginotp from "./Components/Auth/Loginotp";
import Pdfview from "./Components/Pdf/PdfViewer";
import Form from "./Components/Form/Form.js";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Success from "./Components/Success.js";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";

function App() {
  const [sessionTimeOut, setsessionTimeOut] = React.useState(false);
  React.useEffect(() => {
    const x = document.cookie;
    if (x === "") {
      localStorage.clear();
    }
    if (sessionTimeOut === true) {
      localStorage.clear();
      window.location.replace("/");
    }
  }, [sessionTimeOut]);
  const handleOnIdle = (event) => {
    if (event === undefined) {
      setsessionTimeOut(true);
    }
  };
  const handleOnActive = (event) => {};
  const handleOnAction = (e) => {
    if (e !== undefined) {
      setsessionTimeOut(false);
    }
  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <>
      <div className="mainHolder">
        <Router>
          <Route path="/" exact component={Login} />
          <Route path="/loginotp" exact component={Loginotp} />
          <Route path="/pdfview" exact component={Pdfview} />
          <Route path="/form" exact component={Form} />
          <Route path="/success" exact component={Success} />
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
