import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import lockicon from "../../Assets/lock_icon.png";
import * as actions from "../../Action/index";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import close from "../../Assets/close.png";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header.js";
import "./Login.css";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

const Loginotp = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [getOTP, setOTP] = React.useState("");
  const [loader, setloader] = React.useState(false);
  const [otpval, setotpval] = React.useState(false);
  const [otperror, setotperror] = React.useState(false);
  const [resendotpsuccess, setresendotpsuccess] = React.useState(false);
  const [resendotperr, setresendotperr] = React.useState(false);

  console.log("props", props.location.state);

  const handleOTP = (e) => {
    setOTP(e.target.value);
  };

  const SubmitOTP = (e) => {
    if (getOTP === "") {
      setotpval(true);
    } else {
      setotpval(false);
      setresendotpsuccess(false);
      // setloader(true);
      let data = {
        email: props.location.state.email,
        otp: getOTP,
      };

      actions.ValidateOTPAction(data, (res) => {
        console.log("res...", res);
        if (res.statusCode === 200) {
          setloader(false);
          setotperror(false);
          let data = {
            page: "login",
          };
          localStorage.setItem(
            "preSignedUrl",
            props.location.state.preSignedUrl
          );
          localStorage.setItem("accessToken", res.info.accessToken);
          localStorage.setItem("organizationName", res.info.organizationName);
          if (props.location.state.firstTimeLogin === true) {
            history.push({
              pathname: "/pdfview",
              state: data,
            });
          } else {
            history.push({
              pathname: "/form",
              state: data,
            });
          }
        } else {
          console.log("in...");
          setloader(false);
          setotperror(true);
        }
      });
    }
  };

  const ResendOTP = () => {
    setloader(true);
    setotperror(false);
    setotpval(false);
    let data = {
      // messageId: props.location.state.res.messageId,
      email: props.location.state.email,
      messageCode: "",
    };
    actions.LoginAction(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setresendotpsuccess(true);
      } else {
        setloader(false);
        setresendotperr(true);
        // toast.error(res.errorMessage);
      }
    });
  };

  const SignInC = (evt) => {
    if (evt.keyCode === 13) {
      SubmitOTP();
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />

      {loader === true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="loginMode">
            <Grid item xs={12} md={12} sm={12}>
              <img src={lockicon} className="lockicon" />
            </Grid>

            <p>Enter the verification code we just sent to your email</p>

            <div className="otpText">
              <Grid item xs={8} md={12} sm={12}>
                <div className="otpInput">
                  <TextField
                    id="standard-basic"
                    style={{
                      borderBottom: "1px solid black",
                      textAlign: "center",
                    }}
                    value={getOTP}
                    onChange={handleOTP}
                    className="otpInput"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    onKeyUp={SignInC.bind(this)}
                  />
                </div>
                {otpval ? (
                  <div className="errorMess1" style={{ textAlign: "left" }}>
                    Please enter the otp
                  </div>
                ) : (
                  ""
                )}
                {otperror ? (
                  <div className="errorMess1" style={{ textAlign: "left" }}>
                    Please enter valid otp
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </div>

            <div className="resend">
              <Grid item xs={12} md={12} sm={12}>
                <div onClick={ResendOTP}>RESEND</div>
                {resendotpsuccess ? (
                  <div className="errorMess1" style={{ textAlign: "left" }}>
                    OTP sent Successfully.
                  </div>
                ) : (
                  ""
                )}
                {resendotperr ? (
                  <div className="errorMess1" style={{ textAlign: "left" }}>
                    Resend OTP Failed.
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          </div>

          <div className="loginSubmitHolder">
            <Grid item xs={12} md={12} sm={12}>
              <div className="submitlgin submitFlex">
                <button
                  className="btnlgin loginSubmit widthadj"
                  onClick={SubmitOTP}
                >
                  Submit
                </button>
              </div>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default Loginotp;
